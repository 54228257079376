<template>
  <div>
    <data-table
      :responseData="responseData"
      :propsParams="params"
      :header="header"
      @reloadDataFromChild="reloadData"
      v-if="showData"
    >
      <template slot="tbody">
        <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.records">
          <vs-td>
            <div class="flex flex-col items-center gap-3">
              <vx-tooltip text="Confirm" style="width: fit-content">
                <vs-button
                  color="primary"
                  icon-pack="feather"
                  icon="icon-check"
                  type="line"
                  @click="handleShow(tr, indextr)"
                />
              </vx-tooltip>

              <vx-tooltip text="Edit" style="width: fit-content">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  icon="icon-edit"
                  @click="handleEdit(tr)"
                />
              </vx-tooltip>

              <vx-tooltip text="Print" style="width: fit-content">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  icon="icon-printer"
                  @click="openPrintDialog(tr)"
                />
              </vx-tooltip>
            </div>
          </vs-td>
          <vs-td>
            <sr_number_display
              :srNumber="
                tr.debitNotes.PurchaseReturnCode
                  ? tr.debitNotes.PurchaseReturnCode
                  : tr.debitNotes.SrCode
              "
          /></vs-td>
          <vs-td>{{ tr.debitNotes.Code }}</vs-td>
          <vs-td>
            Name : {{ tr.debitNotes.SupplierName }}
            <br />
            Code : {{ tr.debitNotes.SupplierCode }}
            <br />
          </vs-td>
          <vs-td>
            Value : {{ priceFormat(tr.debitNotes.DNValue) }}
            <br />
            Tax : {{ priceFormat(tr.debitNotes.TaxValue) }}
            <br />
            Total : {{ priceFormat(tr.debitNotes.TotalDnValue) }}
          </vs-td>
        </vs-tr>
      </template>
    </data-table>

    <print-dialog
      :active.sync="isPrintDialogActive"
      @closePrintDialog="closePrintDialog"
      :debitNoteID="
        selectedDebitNote && selectedDebitNote.debitNotes
          ? selectedDebitNote.debitNotes.ID
          : 0
      "
    />

    <vs-prompt
      title="Confirmation"
      color="primary"
      @accept="confirmDN(selectedData)"
      @cancel="handleClose"
      @close="handleClose"
      :buttons-hidden="false"
      :active.sync="isPromptShow"
    >
      <div class="con-exemple-prompt">
        Debit Note
        <br />
        Are you sure to confirm debit note
        <b>{{
          this.selectedData.debitNotes ? this.selectedData.debitNotes.Code : ""
        }}</b>
        ?
      </div>
      <!-- <div v-else>
        <div class="con-exemple-prompt">
          Debit Note
          <br />
          Are you sure to confirm debit note
          <b>{{
            this.selectedData.debitNotes
              ? this.selectedData.debitNotes.Code
              : ""
          }}</b>
          ?
        </div>
        <div class="con-exemple-prompt" color="warning">
          <b>Approval Required</b>
          <br />
          The following Quantity and Value for the following items have been
          changed!
          <br />
          <ul>
            <li v-for="item in selectedItemNames" :key="item">
              {{ item }}
            </li>
          </ul>
        </div>
      </div> -->
    </vs-prompt>
  </div>
</template>

<script>
import moment from "moment";
import Detail from "./detail.vue";
import SRNumber from "./sr_number_display.vue";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import printDialog from "../print-dialog.vue";
export default {
  components: {
    detail: Detail,
    sr_number_display: SRNumber,
    "print-dialog": printDialog,
  },
  created() {
    this.$store.watch(
      (state) => state.operatingUnit.filter.selectedTerritories,
      () => {
        this.reloadData(this.params);
      }
    );
  },
  props: {
    grrDate: {
      type: Object,
      default: () => ({}),
    },
    grrType: {
      type: Object,
      default: () => ({}),
    },
    supplierCodes: {
      type: Array,
      default: () => [],
    },
    territoryIDs: {
      type: Array,
      default: () => [],
    },
    supplierCodeOptions: {
      type: Array,
      default: () => [],
    },
    showData: {
      type: Boolean,
      default: false,
    },
    srNumbers: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    grrDate() {
      this.reloadData(this.params);
    },
    grrType() {
      this.reloadData(this.params);
    },
    supplierCodes() {
      this.reloadData(this.params);
    },
    srNumbers() {
      this.reloadData(this.params);
    },
    territoryIDs() {
      this.reloadData(this.params);
    },
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "id",
      },
      header: [
        {
          text: "",
        },
        {
          text: "SR Number",
        },
        {
          text: "Code",
        },
        {
          text: "Supplier",
        },
        {
          text: "Amount",
        },
      ],
      responseData: {},
      selectedData: {},
      isPromptShow: false,
      isNeedApproval: false,
      selectedItemNames: [],
      detailShow: "core vx-col ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      sr_ids: [],
      pr_ids: [],
      showPdf: false,
      pdfUrl: "",
      pdfProxy: null,
      selectedDebitNote: {},
      supplierReturnDetails: null,
      isPrintDialogActive: false,
    };
  },
  methods: {
    reloadData(params) {
      params = {
        ...params,
        status: 7, //draft
        type: "Return",
        grr_start_date:
          Object.keys(this.grrDate).length && this.grrDate.startDate
            ? moment(this.grrDate.startDate).format("YYYY-MM-DD")
            : "",
        grr_end_date:
          Object.keys(this.grrDate).length && this.grrDate.endDate
            ? moment(this.grrDate.endDate).format("YYYY-MM-DD")
            : "",
        grr_type: this.grrType.name === "All" ? undefined : this.grrType.name,
        territory_ids:
          this.$store.getters["operatingUnit/getSelectedTerritoryId"],
      };

      if (this.supplierCodes.some((v) => v.supplier_code !== "All")) {
        params.scds = this.supplierCodes.map((v) => v.supplier_code);
      }

      if (this.srNumbers.some((v) => v.code !== "All")) {
        params.srns = this.srNumbers.map((v) => v.code);
      }

      this.$vs.loading();
      this.$http
        .get("/api/v1/debit-note/return", { params })
        .then((res) => {
          if (res.code < 299) {
            this.$vs.loading.close();
            this.responseData = res.data.records !== null ? res.data : {};
            // this.responseData.lines.forEach((line) => {
            //   if (line.PurchaseReturnID && line.PurchaseReturnID > 0) {
            //     this.pr_ids.push(line.PurchaseReturnID);
            //   } else {
            //     this.sr_ids.push(line.SupplierReturnID);
            //   }
            // });

            // this.pr_ids = [...new Set(this.pr_ids)];
            // this.sr_ids = [...new Set(this.sr_ids)];
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: res.data.message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: err.message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
          });
        });
    },
    handleShow(tr, index) {
      this.selectedData = tr;
      this.isPromptShow = true;
    },

    // displaySRNumber(srNumber) {
    //   if (srNumber.includes(",")) {
    //     return srNumber.split(",").join(", ");
    //   } else {
    //     return `SR${srNumber}`;
    //   }
    // },

    handleEdit(tr) {
      this.selectedData = tr;
      const id = btoa(tr.debitNotes.ID);
      this.$router.push({
        path: `/account-payable/dn-with-or-without-po-ref/dn-edit/${id}`,
      });
    },

    handlePrint(tr) {
      this.selectedData = tr;
      // this.$router.push({
      //   path: `/account-payable/dn-with-or-without-po-ref-draft/print-tax-return/${tr.debitNotes.ID}`,
      // });
      window.open(
        `/account-payable/dn-with-or-without-po-ref-draft/print-tax-return/${tr.debitNotes.ID}`,
        "_blank"
      );
    },

    handleClose() {
      this.isPromptShow = false;
      this.selectedData = {};
    },

    confirmDN(data) {
      if (
        !data.debitNotes.TaxInvoiceReferenceID ||
        !data.debitNotes.TaxInvoiceReferenceValue
      ) {
        if (data.debitNotes.TaxInvoiceReferenceRefText === "") {
          this.$vs.notify({
            title: "Error",
            text: "Please select Tax Invoice Reference first",
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
          });
          this.$vs.loading.close();
          return;
        }
      }
      this.$vs.loading();
      this.$http
        .patch("/api/v1/debit-note/return/status", {
          id: data.debitNotes.ID,
          status: 1,
        })
        .then((res) => {
          if (res.code < 299) {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Success",
              text: res.message,
              color: "success",
              iconPack: "feather",
              icon: "icon-check-circle",
              position: "top-right",
            });
            this.reloadData(this.params);
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: res.message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: err.message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
          });
        });
      this.isPromptShow = false;
    },

    handlePrintPDFPreview() {
      const fileName = `Nota retur_${this.selectedDebitNote.debitNotes.Code}.pdf`;
      this.pdfProxy.save(fileName);
      this.handleClosePopUp();
    },

    handleShowPopUp() {
      this.showPdf = true;
    },

    handleClosePopUp() {
      this.pdfProxy = false;
      this.showPdf = false;
      this.pdfUrl = "";
    },
    generateHeader(
      doc,
      pageWidth,
      pageHeight,
      padding,
      debitNote,
      taxInvoiceReference,
      company,
      supplier
    ) {
      doc.setLineWidth(0.07);
      doc.rect(
        this.setPos(pageWidth, 0, padding),
        this.setPos(pageHeight, 0, padding),
        this.setPos(pageWidth, pageWidth, padding),
        1.8
      );

      let posY = this.setPos(pageHeight, 0.5, padding);
      const title = "NOTA RETUR";
      const titleLength = doc.getTextDimensions(title).w;
      const innerPadding = 0.3;
      doc.setFontSize(15);
      doc.setFont("helvetica", "bold");
      doc.text(
        title,
        this.setPos(pageWidth, pageWidth / 2 + titleLength / 4, padding),
        this.setPos(pageHeight, posY, 0),
        "center"
      );

      const spacing = 0.7;

      posY += 0.5;

      doc.setFontSize(10);

      doc.setFont("helvetica", "normal");
      const number = `Nomor`;
      doc.text(
        number,
        this.setPos(pageWidth, pageWidth - pageWidth / 3, padding),
        this.setPos(pageHeight, posY, 0)
      );

      const numberValue = `: ${debitNote.Code}`;

      doc.text(
        numberValue,
        this.setPos(pageWidth, pageWidth - pageWidth / 3 + 2, padding),
        this.setPos(pageHeight, posY, 0)
      );

      posY += 0.5;

      const taxNumber = "Atas Faktur Pajak Nomor";
      doc.text(taxNumber, this.setPos(pageWidth, innerPadding, padding), posY);

      const taxNumberValue = `: ${
        Object.keys(debitNote).length > 0 &&
        debitNote.TaxInvoiceReferenceRefText
          ? debitNote.TaxInvoiceReferenceRefText
          : taxInvoiceReference.TaxReturnNumber
      }`;

      doc.text(
        taxNumberValue,
        this.setPos(pageWidth, innerPadding + 4.3, padding),
        posY
      );

      doc.text(taxNumber, this.setPos(pageWidth, innerPadding, padding), posY);

      const date = `tgl`;
      doc.text(
        date,
        this.setPos(pageWidth, pageWidth - pageWidth / 3, padding),
        this.setPos(pageHeight, posY, 0)
      );

      const dateValue = `: ${this.formatDate(
        debitNote.TaxInvoiceReferenceRefDate
          ? debitNote.TaxInvoiceReferenceRefDate
          : taxInvoiceReference.TaxReturnDate,
        true
      )}`;

      doc.text(
        dateValue,
        this.setPos(pageWidth, pageWidth - pageWidth / 3 + 2, padding),
        this.setPos(pageHeight, posY, 0)
      );

      const startLine = posY + 0.3;
      posY += 0.9;

      doc.text(
        "PEMBELI",
        this.setPos(pageWidth, innerPadding, padding),
        this.setPos(pageHeight, posY, 0)
      );

      posY += spacing;

      doc.text(
        "Nama",
        this.setPos(pageWidth, innerPadding, padding),
        this.setPos(pageHeight, posY, 0)
      );

      doc.text(
        `: ${company.Name}`,
        this.setPos(pageWidth, innerPadding + 2, padding),
        this.setPos(pageHeight, posY, 0)
      );

      posY += spacing;

      const addrHeader = "Alamat";
      const addrWidth = doc.getTextDimensions(addrHeader).w;
      const addrHeight = doc.getTextDimensions(addrHeader).h;
      const companyAddr = company.Address;
      const companyAddrLines = doc.splitTextToSize(
        companyAddr,
        pageWidth - addrWidth - 1
      );

      doc.text(
        addrHeader,
        this.setPos(pageWidth, innerPadding, padding),
        this.setPos(pageHeight, posY, 0)
      );

      doc.text(
        ":",
        this.setPos(pageWidth, innerPadding + 2, padding),
        this.setPos(pageHeight, posY, 0)
      );

      doc.text(
        companyAddrLines,
        this.setPos(pageWidth, innerPadding + 2.25, padding),
        this.setPos(pageHeight, posY, 0)
      );

      posY += addrHeight * companyAddrLines.length + 0.35;

      doc.text(
        "NPWP",
        this.setPos(pageWidth, innerPadding, padding),
        this.setPos(pageHeight, posY, 0)
      );

      doc.text(
        `: ${company.Npwp}`,
        this.setPos(pageWidth, innerPadding + 2, padding),
        this.setPos(pageHeight, posY, 0)
      );

      posY += spacing;

      doc.text(
        "KEPADA PENJUAL",
        this.setPos(pageWidth, innerPadding, padding),
        this.setPos(pageHeight, posY, 0)
      );

      posY += spacing;

      doc.text(
        "Nama",
        this.setPos(pageWidth, innerPadding, padding),
        this.setPos(pageHeight, posY, 0)
      );

      doc.text(
        `: ${supplier.Name}`,
        this.setPos(pageWidth, innerPadding + 2, padding),
        this.setPos(pageHeight, posY, 0)
      );

      posY += spacing;

      const supplierAddr = `${supplier.Address}, ${supplier.SubDistrict}, ${supplier.District}, ${supplier.City}, ${supplier.Province}`;
      const supplierAddrHeight = doc.getTextDimensions(supplierAddr).h;
      const supplierAddrLines = doc.splitTextToSize(
        supplierAddr,
        pageWidth - addrWidth - 2
      );

      doc.text(
        addrHeader,
        this.setPos(pageWidth, innerPadding, padding),
        this.setPos(pageHeight, posY, 0)
      );

      doc.text(
        ":",
        this.setPos(pageWidth, innerPadding + 2, padding),
        this.setPos(pageHeight, posY, 0)
      );

      doc.text(
        supplierAddrLines,
        this.setPos(pageWidth, innerPadding + 2.25, padding),
        this.setPos(pageHeight, posY, 0)
      );

      posY += supplierAddrLines.length * supplierAddrHeight + 0.35;

      doc.text(
        "NPWP",
        this.setPos(pageWidth, innerPadding, padding),
        this.setPos(pageHeight, posY, 0)
      );

      doc.text(
        `: ${supplier.Npwp}`,
        this.setPos(pageWidth, innerPadding + 2, padding),
        this.setPos(pageHeight, posY, 0)
      );

      posY += spacing;

      //Draw right line
      doc.line(
        this.setPos(pageWidth, 0, padding),
        this.setPos(pageHeight, startLine, 0),
        this.setPos(pageWidth, 0, padding),
        this.setPos(pageHeight, posY, 0)
      );

      //Draw left line
      doc.line(
        pageWidth + padding,
        this.setPos(pageHeight, startLine, 0),
        pageWidth + padding,
        this.setPos(pageHeight, posY, 0)
      );

      return posY;
    },
    generateReturnLine(doc, posY, pageHeight, lines, debitNote, padding) {
      const header = [
        { header: "No. Urut", dataKey: "no" },
        { header: "Macam dan Jenis Barang Kena Pajak", dataKey: "item_name" },
        { header: "Kuantum", dataKey: "quantity" },
        { header: "Harga Satuan menurut Faktur Pajak", dataKey: "price" },
        { header: "Harga BKP yang dikembalikan (Rp)", dataKey: "value" },
      ];

      const body = this.skuGrouping(lines);
      // console.log("body ges", body);

      autoTable(doc, {
        startY: posY,
        columns: header,
        body: body,
        theme: "plain",
        styles: {
          lineWidth: 0,
          fontSize: 10,
          font: "helvetica",
          fontStyle: "normal",
          valign: "middle",
          lineColor: [0, 0, 0], //black
        },
        showHead: "everyPage",
        margin: {
          left: padding,
          right: padding,
          bottom: padding,
        },
        columnStyles: {
          no: { cellWidth: 1.5, cellPadding: 0 },
          item_name: { cellWidth: 9, cellPadding: 0 },
          quantity: { cellWidth: 3.5, cellPadding: 0 },
          price: { cellPadding: 0 },
          value: { cellWidth: 6, cellPadding: 0 },
        },
        didParseCell: (data) => {
          const columnIndex = data.column.index;
          const rowIndex = data.row.index;

          data.cell.styles.cellPadding = 0.14;

          if (data.section === "head") {
            if (columnIndex !== 1) {
              data.cell.styles.halign = "center";
              data.cell.styles.valign = "middle";
            } else {
              data.cell.styles.halign = "left";
              data.cell.styles.valign = "middle";
            }
          } else {
            if (columnIndex === 1) {
              data.cell.styles.halign = "left";
              data.cell.styles.valign = "middle";
            } else if (columnIndex === 2) {
              data.cell.styles.halign = "center";
              data.cell.styles.valign = "middle";
            } else {
              data.cell.styles.halign = "right";
              data.cell.styles.valign = "middle";
            }

            if (rowIndex < body.length - 1) {
              return;
            }

            // if (columnIndex === 0) {
            //   data.cell.styles.halign = "left";
            //   data.cell.colSpan = 4;
            // }
          }
        },
        didDrawCell(data) {
          const cellX = data.cell.x;
          const cellY = data.cell.y;
          if (data.row.section === "head") {
            doc.setLineWidth(0.07);
            doc.setDrawColor(0, 0, 0);
            //draw top line for all cells
            doc.line(cellX, cellY, cellX + data.cell.width, cellY);

            //draw bottom line for all cells
            doc.line(
              cellX,
              cellY + data.cell.height,
              cellX + data.cell.width,
              cellY + data.cell.height
            );

            //draw left line
            if (data.column.index === 0) {
              doc.line(cellX, cellY, cellX, cellY + data.cell.height);
            }

            //draw right line
            doc.line(
              cellX + data.cell.width,
              cellY,
              cellX + data.cell.width,
              cellY + data.cell.height
            );
          } else {
            //draw left line
            doc.setLineWidth(0.07);
            doc.setDrawColor(0, 0, 0);

            if (data.column.index === 0) {
              doc.line(cellX, cellY, cellX, cellY + data.cell.height);
            }

            //draw right line
            doc.line(
              cellX + data.cell.width,
              cellY,
              cellX + data.cell.width,
              cellY + data.cell.height
            );

            const posY = cellY + data.cell.height;
            const rowIndex = data.row.index;

            console.log("row index", rowIndex, rowIndex % 35);
            // if (columnIndex === 0) {
            //   itemCount++;
            // }

            // if (itemCount > 0 && itemCount % 36 === 0) {
            //   doc.addPage();
            //   itemCount = 0;
            // }

            if (posY >= pageHeight + 0.5) {
              doc.setLineWidth(0.07);
              doc.setDrawColor(0, 0, 0);

              doc.line(
                cellX,
                cellY + data.cell.height,
                cellX + data.cell.width,
                cellY + data.cell.height
              );
            }

            if (rowIndex === body.length - 1) {
              //draw top line for all cells
              // doc.line(cellX, cellY, cellX + data.cell.width, cellY);

              //draw bottom line for all cells
              doc.line(
                cellX,
                cellY + data.cell.height,
                cellX + data.cell.width,
                cellY + data.cell.height
              );
            }
          }
        },
      });

      return doc.lastAutoTable.finalY;
    },

    skuGrouping(lines) {
      const filteredItems = {};
      const body = [];
      lines.forEach((line, index) => {
        const price = line.DNValue / line.DNQuantity;

        if (index === 0) {
          const obj = {};
          obj[price] = {
            quantity: line.DNQuantity,
            value: line.DNValue,
            price: price,
            unit: line.UnitName,
            name: line.ItemName,
          };
          filteredItems[line.ItemUnitID] = obj;
          return; //next iteration
        }

        if (line.ItemUnitID in filteredItems) {
          let found = false;

          for (const priceKey in filteredItems[line.ItemUnitID]) {
            if (priceKey === price) {
              filteredItems[line.ItemUnitID][priceKey].quantity +=
                line.DNQuantity;
              filteredItems[line.ItemUnitID][priceKey].value += line.DNValue;
              found = true;
              break;
            }
          }

          if (!found) {
            filteredItems[line.ItemUnitID][price] = {
              quantity: line.DNQuantity,
              value: line.DNValue,
              price: price,
              unit: line.UnitName,
              name: line.ItemName,
            };
          }
        } else {
          const obj = {};
          obj[price] = {
            quantity: line.DNQuantity,
            value: line.DNValue,
            price: price,
            unit: line.UnitName,
            name: line.ItemName,
          };
          filteredItems[line.ItemUnitID] = obj;
          // const obj = {
          //   price: [],
          // };
          // filteredItems[line.ItemUnitID] = obj;
          // filteredItems[line.ItemUnitID][price].push({
          //   quantity: line.DNQuantity,
          //   value: line.DNValue,
          //   price: price,
          //   unit: line.UnitName,
          //   name: line.ItemName,
          // });
        }
      });

      // Object.keys(filteredItems).forEach((key, index) => {
      //   filteredItems[key].forEach((item) => {
      //     item.forEach((data) => {
      //       body.push({
      //         no: index + 1,
      //         item_name: data.name,
      //         quantity: `${data.quantity} ${data.unit}`,
      //         price: this.priceFormat(data.price),
      //         value: this.priceFormat(data.value),
      //       });
      //     });
      //   });
      // });
      let index = 0;
      for (const itemUnitID in filteredItems) {
        for (const price in filteredItems[itemUnitID]) {
          index++;
          body.push({
            no: index,
            item_name: filteredItems[itemUnitID][price].name,
            quantity: `${filteredItems[itemUnitID][price].quantity} ${filteredItems[itemUnitID][price].unit}`,
            price: this.priceFormat(filteredItems[itemUnitID][price].price),
            value: this.priceFormat(filteredItems[itemUnitID][price].value),
          });
        }
      }

      return body;
    },

    generateFooter(doc, posY, debitNote, padding) {
      const header = [
        { header: "", dataKey: "placeholder" },
        { header: "", dataKey: "divider" },
        { header: "", dataKey: "value" },
      ];

      const body = [
        {
          placeholder: "Jumlah Harga BKP yang dikembalikan\n",
          divider: ":",
          value: this.priceFormat(debitNote.DNValue),
        },
        {
          placeholder: "Dikurangi Potongan Harga",
          divider: ":",
          value: "0",
        },
        {
          placeholder: "Dasar Pengenaan Pajak",
          divider: ":",
          value: this.priceFormat(debitNote.DNValue),
        },
        {
          placeholder: "Pajak Pertambahan Nilai yang diminta kembali",
          divider: ":",
          value: this.priceFormat(debitNote.TaxValue),
        },
        {
          placeholder: "Pajak Penjualan Atas Barang Mewah yang diminta kembali",
          divider: ":",
          value: "",
        },
      ];

      autoTable(doc, {
        startY: posY,
        columns: header,
        body: body,
        theme: "plain",
        styles: {
          lineWidth: 0,
          fontSize: 10,
          font: "helvetica",
          fontStyle: "normal",
          valign: "middle",
          lineColor: [0, 0, 0], //black
        },
        showHead: "never",
        margin: {
          left: padding,
          right: padding,
          bottom: padding,
        },
        columnStyles: {
          placeholder: { cellWidth: 9.94, cellPadding: 0.14 },
          divider: { cellWidth: 10.312, cellPadding: 0.14 },
          value: { cellPadding: 0.14 },
        },
        didParseCell(data) {
          if (data.section === "head") {
            return;
          }

          const rowIndex = data.row.index;
          const columnIndex = data.column.index;
          const cellX = data.cell.x;
          const cellY = data.cell.y;

          if (columnIndex === header.length - 1) {
            data.cell.styles.halign = "right";
          }

          if (rowIndex === 0) {
            data.cell.styles.valign = "top";
          }

          if (
            rowIndex === body.length - 1 ||
            posY >= doc.internal.pageSize.height - 3
          ) {
            doc.setLineWidth(0.07);
            doc.setDrawColor(0, 0, 0);

            doc.line(
              cellX,
              cellY + data.cell.height,
              cellX + data.cell.width,
              cellY + data.cell.height
            );
          }
        },
        didDrawCell(data) {
          if (data.section === "head") {
            return;
          }

          const rowIndex = data.row.index;
          const columnIndex = data.column.index;

          const cellX = data.cell.x;
          const cellY = data.cell.y;
          const cellWidth = data.cell.width;
          const cellHeight = data.cell.height;

          doc.setLineWidth(0.07);
          doc.setDrawColor(0, 0, 0);

          if (rowIndex === 0) {
            doc.setLineWidth(0.07);
            doc.setDrawColor(0, 0, 0);

            //draw top line for all cells
            doc.line(cellX, cellY, cellX + cellWidth, cellY);

            doc.line(
              cellX,
              cellY + cellHeight,
              cellX + cellWidth,
              cellY + cellHeight
            );
          }

          if (columnIndex === 0) {
            //draw left line
            doc.line(cellX, cellY, cellX, cellY + cellHeight);
          } else {
            doc.line(
              cellX + cellWidth,
              cellY,
              cellX + cellWidth,
              cellY + cellHeight
            );
          }

          // if (rowIndex === body.length - 1 || rowIndex === 0) {
          //   doc.setLineWidth(0.07);
          //   doc.setDrawColor(0, 0, 0);

          //   //draw bottom line for all cells
          //   doc.line(
          //     cellX,
          //     cellY + cellHeight,
          //     cellX + cellWidth,
          //     cellY + cellHeight
          //   );
          // }
        },
      });

      return doc.lastAutoTable.finalY;
    },

    generateSign(doc, posY, pageWidth, pageHeight, padding, debitNote) {
      doc.setLineWidth(0.07);
      doc.rect(
        this.setPos(pageWidth, 0, padding),
        this.setPos(pageHeight, posY, 0),
        this.setPos(pageWidth, pageWidth, padding),
        4
      );

      const endY = posY + 4;

      const date = `Jakarta, ${
        debitNote.TaxReturnDate
          ? this.formatDate(debitNote.TaxReturnDate, true)
          : "-"
      }`;
      const dateWidth = doc.getTextDimensions(date).w;

      const buyer = "Pembeli";
      const buyerWidth = doc.getTextDimensions(buyer).w;

      const name = "(     Harsinto     )";
      const nameWidth = doc.getTextDimensions(name).w;

      const director = "Direktur";
      const directorWidth = doc.getTextDimensions(director).w;

      posY += 0.5;
      const startPos = pageWidth - pageWidth / 3;
      const buyerPos = startPos + (dateWidth - buyerWidth) / 2;
      const namePos = startPos + (dateWidth - nameWidth) / 2;
      const directorPos = startPos + (dateWidth - directorWidth) / 2;

      doc.text(
        date,
        this.setPos(pageWidth, pageWidth - pageWidth / 3, padding),
        this.setPos(pageHeight, posY, 0)
      );

      posY += 0.5;

      doc.text(
        buyer,
        this.setPos(pageWidth, buyerPos, padding),
        this.setPos(pageHeight, posY, 0)
      );

      posY += 2;

      doc.text(
        name,
        this.setPos(pageWidth, namePos, padding),
        this.setPos(pageHeight, posY, 0)
      );

      posY += 0.3;

      doc.setLineWidth(0.03);
      doc.line(
        this.setPos(pageWidth, startPos - 0.5, padding),
        this.setPos(pageHeight, posY, 0),
        this.setPos(pageWidth, startPos + dateWidth + 0.5, padding),
        this.setPos(pageHeight, posY, 0)
      );

      posY += 0.5;

      doc.text(
        director,
        this.setPos(pageWidth, directorPos, padding),
        this.setPos(pageHeight, posY, 0)
      );

      return endY;
    },

    generateOtherInfo(doc, posY, pageWidth, pageHeight, padding) {
      const innerPadding = 0.3;
      doc.setLineWidth(0.07);
      doc.rect(
        this.setPos(pageWidth, 0, padding),
        this.setPos(pageHeight, posY, 0),
        this.setPos(pageWidth, pageWidth, padding),
        1.5
      );

      posY += 0.5;

      doc.text(
        "Lembar ke-1 untuk Pengusaha Kena Pajak yang menerbitkan Faktur Pajak",
        this.setPos(pageWidth, innerPadding, padding),
        this.setPos(pageHeight, posY, 0)
      );

      posY += 0.5;

      doc.text(
        "Lembar ke-2 untuk Pembeli",
        this.setPos(pageWidth, innerPadding, padding),
        this.setPos(pageHeight, posY, 0)
      );

      return posY;
    },
    getPrice(i) {
      const dnValue = this.selectedDebitNote.lines[i].DNValue;
      const qty = this.selectedDebitNote.lines[i].DNQuantity;

      return dnValue / qty;
    },
    async getDebitNote(id) {
      this.$vs.loading();
      // let resp = await this.$http.get(`/api/wms/v1/simple-outbound-planner/handover-data/${this.handover_id}`);
      let resp = await this.$http.get(`/api/v1/debit-note/return/detail`, {
        params: {
          id: id,
        },
      });
      if (resp.code == 200) {
        this.selectedDebitNote = resp.data;
        this.$vs.loading.close();
      } else {
        this.$vs.loading.close();
      }
    },
    async getSupplierReturnDetails() {
      this.$vs.loading();
      let pr_ids = [];
      let sr_ids = [];
      this.selectedDebitNote.lines.forEach((line) => {
        if (line.SupplierReturnID) {
          sr_ids.push(line.SupplierReturnID);
        } else if (line.PurchaseReturnID) {
          pr_ids.push(line.PurchaseReturnID);
        }
      });

      pr_ids = [...new Set(pr_ids)];
      sr_ids = [...new Set(sr_ids)];
      const params = {
        pr_ids,
        sr_ids,
        is_edit: true,
      };
      try {
        const response = await this.$http.get(
          "/api/v1/debit-note/supplier-return/detail",
          {
            params,
          }
        );
        this.supplierReturnDetails = response.data;
        console.log("supl", this.supplierReturnDetails);
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        throw e;
      }
    },
    async printPDF(tr) {
      try {
        console.log(tr);
        await this.getDebitNote(tr.debitNotes.ID);
        await this.getSupplierReturnDetails();

        const padding = 1.2;
        const height = 37.1856;
        const width = 28.6512;
        const pageWidth = width - padding * 2;
        const pageHeight = height - padding * 2;

        const pdf = new jsPDF({
          unit: "cm",
          format: [width, height],
        });

        pdf.setFontSize(11);

        let cursorY = this.generateHeader(
          pdf,
          pageWidth,
          pageHeight,
          padding,
          this.selectedDebitNote.debitNotes,
          this.selectedDebitNote.taxInvoiceReference,
          this.selectedDebitNote.company,
          this.selectedDebitNote.supplier
        );

        cursorY = this.generateReturnLine(
          pdf,
          cursorY,
          pageHeight,
          this.selectedDebitNote.lines,
          this.selectedDebitNote.debitNotes,
          padding
        );

        // console.log("cursorY", cursorY);

        // console.log("height", height - padding);

        const currentPage = pdf.internal.getCurrentPageInfo().pageNumber;
        // console.log("current page", currentPage);
        const buffer = currentPage === 1 ? 11.5 : 11.1;
        // console.log("cursorY plus", buffer, cursorY + buffer);
        if (cursorY + buffer >= height - padding) {
          pdf.addPage();
          cursorY = padding;
        }

        cursorY = this.generateFooter(
          pdf,
          cursorY,
          this.selectedDebitNote.debitNotes,
          padding
        );

        cursorY = this.generateSign(
          pdf,
          cursorY,
          pageWidth,
          pageHeight,
          padding,
          this.selectedDebitNote.debitNotes
        );

        cursorY = this.generateOtherInfo(
          pdf,
          cursorY,
          pageWidth,
          pageHeight,
          padding
        );

        const pageCount = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
          pdf.setPage(i);
          pdf.setFontSize(10);
          pdf.text(
            `${i}/${pageCount}`,
            this.setPos(pageWidth, pageWidth - 2, padding),
            pdf.internal.pageSize.height - 1
          );
        }
        const url = pdf.output("dataurlstring");
        this.pdfUrl = url;
        this.pdfProxy = pdf;
        this.handleShowPopUp();
        this.$vs.loading.close();
        // pdf.save(docName);
      } catch (e) {
        this.$vs.loading.close();
        console.log(e);
      }
    },

    setPos(pageWidth, pos, offset) {
      if (pos >= pageWidth) {
        return pageWidth;
      } else {
        if (pos <= 0) {
          return offset;
        } else {
          return (pos += offset);
        }
      }
    },

    formatDate(val, long = null) {
      const momentDate = moment.utc(val);
      return momentDate.format(long ? "DD.MM.YYYY" : "DD MMMM YYYY");
    },

    closePrintDialog(is_remove_dn = true) {
      this.isPrintDialogActive = false;
      if (is_remove_dn) {
        this.selectedDebitNote = {};
      }
    },

    openPrintDialog(tr) {
      this.isPrintDialogActive = true;
      this.selectedDebitNote = tr;
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
    .core-leave-to

    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.highlight > td {
  background-color: #d0cdf0;
}

.con-vs-checkbox {
  margin-left: auto;
  margin-right: auto;
}
</style>
