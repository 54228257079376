<template>
  <vs-prompt
    @cancel="close"
    @accept="print"
    @close="close"
    :active.sync="isPromtActive"
    accept-text="Print"
    title="Print"
  >
    <div class="pl-3 flex flex-col gap-5">
      <h5>Select print format</h5>
      <div class="flex flex-col gap-3">
        <vs-radio
          v-model="selectedFormat"
          vs-name="format"
          :vs-value="DN_RETURN_FORMAT"
          >Default Format</vs-radio
        >

        <vs-radio
          v-model="selectedFormat"
          vs-name="format"
          :vs-value="DN_CLAIM_FORMAT"
          >Claim Format</vs-radio
        >
      </div>
    </div>
  </vs-prompt>
</template>

<script>
export default {
  data() {
    return {
      DN_RETURN_FORMAT: "dn-return",
      DN_CLAIM_FORMAT: "dn-claim",
      isPromtActive: false,
      selectedFormat: "dn-return",
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    debitNoteID: {
      type: Number,
      default: null,
    },
  },
  watch: {
    active() {
      this.isPromtActive = this.active;
    },
  },
  methods: {
    close() {
      this.$emit("closePrintDialog");
    },
    print() {
      this.$emit("closePrintDialog", false);
      let url = "/account-payable/dn-with-or-without-po-ref/debit-note-print";

      const params = {
        debit_note_id: this.debitNoteID,
      };

      if (this.selectedFormat === this.DN_CLAIM_FORMAT) {
        params.format = this.DN_CLAIM_FORMAT;
      } else if (this.selectedFormat === this.DN_RETURN_FORMAT) {
        params.format = this.DN_RETURN_FORMAT;
      } else {
        return;
      }

      url += `?param=${btoa(JSON.stringify(params))}`;

      window.open(url, "_blank");
      this.selectedFormat = this.DN_RETURN_FORMAT;
    },
  },
};
</script>
